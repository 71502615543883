.appbody {
    width: 50%;
    margin: auto;
    background-color: #121e2b;
    min-height: 100vh;
}

@media screen and (max-width: 640px) {
    .appbody {
      width: 90%;
    }
  }

.fullbody {
    background-image: linear-gradient(90deg, #121e2b 25%, #0b141f 25%, #0b141f 50%, #121e2b 50%, #121e2b 75%, #0b141f 75%, #0b141f 100%);
    background-size: 40.00px 40.00px;
    min-height: 100vh;
}

.hr-white{
    background-color: #ddd;
}