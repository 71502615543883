.txt-contacts{
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;
  color: #ddd;
}

.contact-info{
    margin: auto;
    display: flex;
    width: 100%;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
  
  .card.card{
    background: #121e2b;
    padding: 0 20px;
    margin: 0 10px;
    width: calc(33% - 20px);
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border: none;
  }
  
  .card-icon{
    font-size: 28px;
    background: #ff6348;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px !important;
    border-radius: 50%;
    transition: 0.3s linear;
  }
  
  .card:hover .card-icon{
    background: none;
    color: #ff6348;
    transform: scale(1.6);
  }
  
  .card p{
    margin-top: 20px;
    font-weight: 300;
    letter-spacing: 2px;
    max-height: 0;
    opacity: 0;
    transition: 0.3s linear;
  }
  
  .card:hover p{
    max-height: 40px;
    opacity: 1;
  }
  
  
  @media screen and (max-width:800px) {
    .contact-info{
      flex-direction: column;
    }
    .card{
      width: 100%;
      max-width: 300px;
      margin: 10px 0;
    }
  }
  