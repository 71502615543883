*{
    margin: 0;
    padding: 0;
    font-family: "montserrat",sans-serif;
  }
  .testimonials{
    padding: 40px 0;
    color: #434343;
    text-align: center;
  }
  .inner{
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .col{
    flex: 33.33%;
    max-width: 33.33%;
    box-sizing: border-box;
    padding: 15px;
  }
  .testimonial{
    background: #0b141f;
    padding: 30px;
  }
  .testimonial img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .name{
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .stars{
    margin-bottom: 20px;
  }
  
  
  @media screen and (max-width:960px) {
  .col{
    flex: 100%;
    max-width: 80%;
  }
  }
  
  @media screen and (max-width:600px) {
  .col{
    flex: 100%;
    max-width: 100%;
  }
  }
  