.description{
    text-align: center;
    color: #ddd;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.about-us{
    margin:auto;
    width: 95%;
}

.img-logo{
    width: 50%;
    height: auto;
}

.bestQuality{
    text-align: center;
    margin: auto;
    width: 50%;
    padding: 10px;
    color: #ddd;
}

.buttons{
    background-color: #ff6348;
    display: inline-block;
    color: #fff;
    padding: 0;
    width:120px;
    height:35px;
    border: none;
}
.buttons:focus {outline:0;}